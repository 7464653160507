<template>
	<!--自动更新-->
	<div class="wrap" v-if="showWrap">
	  <div class="wrap-content">
			<div class="top">
				正在更新版本
				<span>{{updateObj.last}}</span>
			</div>
			<div class="middle">
				<h3>{{updateObj.status}}</h3>
				<div class="middle-content">
					<progress id="pro"></progress>
					<span style="margin-left:0.1rem;">{{ updateObj.statusPosName }}</span>
					/
					<span>{{updateObj.statusSizeName}}</span>
				</div>
			</div>
	    
	    <div class="bottom">
	      <div class="bottom-list" v-for="(item, index) of updateObj.version" :key="index">
	        <div>
	          版本号:<span>{{ item.updateVersion }}</span> 
	        </div>
	        <div>
	         更新时间: <span>{{ item.createDate }}</span>
	        </div>
	        <div>
	          更新内容:
						<pre>{{ item.updateVersionRemark }}</pre>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>
	 <!-- 登录中 -->
	<div class="logining">登录中...</div>
	<div class="error">
		<span>{{deviceKey}}</span>
		<span>{{updateError}}</span>
		<span>{{errMessage}}</span>
	</div>
</template>

<script setup>
import { onBeforeUnmount, ref,reactive} from 'vue'
import { useRouter,useRoute } from 'vue-router'
import axios from 'axios'
import $request from '../assets/js/request.js'
let router = useRouter()
const route = useRoute()
let deviceKey=ref('')//设备编号
let errMessage=ref('')//错误提示
let updateError=ref('')//更新错误提示
let showWrap=ref(false)//是否显示自动更新
let updateObj=reactive({})//更新对象
let timer=''
//获取公钥，然后登陆
getPublicLogin()
//---登录公钥加密(登录，注册模块使用)
function loginEncrypt (msg) {
  let key=sessionStorage.publicKey||''
  let number=0
  if(sessionStorage.publicTimer&&sessionStorage.localityTimer){
    number=parseInt((sessionStorage.publicTimer-sessionStorage.localityTimer).toFixed(2))
  }
  const jsencrypt = new JSEncrypt()
  jsencrypt.setPublicKey(key)
  let string=JSON.stringify({
    v:msg,
    t:parseInt((Date.parse(new Date())/1000+number).toFixed(2))
  })
  return jsencrypt.encrypt(string)
}
//---获取公钥,之后再进行登录
function getPublicLogin() {
  let baseUrl= ''
  if(window.location.protocol=='https:'){
	  baseUrl= 'https://service.tourbida.com/'
  }else{
	  baseUrl= 'http://service.tourbida.com/'
  }
  axios.get(baseUrl+'/tbd-system/login/publicKey').then((res) => {
    if (res.data.code == '00') {
      sessionStorage.publicKey=res.data.data
      sessionStorage.publicTimer=res.data.main
      sessionStorage.localityTimer=Date.parse(new Date())/1000
      if(Base.isWeb){
			  //如果是网页版本
			  login()
      }else{
			  getVersion()
      }
      
    }
  })
}
		
//---获取版本号
function getVersion() {
	 axios.get('/package.json').then((res) => {
    if (res.status == 200) {
      let vs = parseInt(res.data.version)
      localStorage.version = localStorage.version || 0
      if (vs > localStorage.version) {
        localStorage.version = vs
        localStorage.versionName = res.data.versionName
      }
      login()
    }
  })
}
//---登录
function login(){
  Base.initCode((code)=>{
    //页面路由增加的hardKey优先
    let key=route.query.hardKey||code
    deviceKey.value=key
    let data={
      deviceType: 'OrderScreen',
      deviceKey:loginEncrypt(key),
      userName: '',
      userPwd: '',
      login: true,
      versionInt: localStorage.version// 当前版本号
    }
    $request('tbd-system/login/in', data).then((res) => {
      if (res.code == '00') {
        deviceKey.value=''
        errMessage.value=''
        sessionStorage.config=JSON.stringify(res.data)
        let hardKey=route.query.hardKey||''
        if(Base.isWeb){
          //网页版直接跳转
          let displayType=res.data.config.displayType
          if(displayType&&displayType=='rowNumber'){
            router.push('/sortScreen?hardKey='+hardKey+'&deviceKey='+key)//排号屏页面
          }else if(displayType&&displayType=='rowNumberPhone') {
            router.push('/sortScreenPhone?hardKey='+hardKey+'&deviceKey='+key)//排号屏页面
          }else if(displayType&&displayType=='capacity'){
            router.push('/capacityScreen?hardKey='+hardKey)//容量屏页面
          }else if(displayType&&displayType=='cameraData') {
            router.push('/cameraDataScreen?hardKey='+hardKey)//摄像头数据页面
          }else if(displayType&&displayType=='peopleNumber') {
            router.push('/scenicStatistics?hardKey='+hardKey)//园区人数统计页面
          }else{
            router.push('/sortScreen?hardKey='+hardKey+'&deviceKey='+key)//排号屏页面
          }
        }else{
          //客户端更新之后再跳转
          deviceOnUpdate(res.data, function () {
            let displayType=res.data.config.displayType
            if(displayType&&displayType=='rowNumber'){
              router.push('/sortScreen?hardKey='+hardKey+'&deviceKey='+key)//排号屏页面
            }else if(displayType&&displayType=='rowNumberPhone') {
              router.push('/sortScreenPhone?hardKey='+hardKey+'&deviceKey='+key)//排号屏页面
            }else if(displayType&&displayType=='capacity'){
              router.push('/capacityScreen?hardKey='+hardKey)//容量屏页面
            }else if(displayType&&displayType=='cameraData') {
              router.push('/cameraDataScreen?hardKey='+hardKey)//摄像头数据页面
            }else if(displayType&&displayType=='peopleNumber') {
              router.push('/scenicStatistics?hardKey='+hardKey)//园区人数统计页面
            }
          })
        }
						
      }else{
        errMessage.value=res.message
        timer=setTimeout(()=>{
          login()
        },5000)
        onBeforeUnmount(()=>{
          clearTimeout(timer)
        })
      }
    }).catch(()=>{
      timer=setTimeout(()=>{
        login()
      },5000)
      onBeforeUnmount(()=>{
        clearTimeout(timer)
      })
    })
  })
}
//---设备是否需要更新
function deviceOnUpdate(res, func) {
		  var data = res || {}
		  var version = data.version || []
		  // 判断是否需要更新
		  if (Base.isWeb || !version || !version.length) {
		    func && func()
		    return
		  }
		  // 需要更新则不执行登录函数
		  showWrap.value = true
		  var last = version[0]
		  updateObj.last = last.updateVersion
		  updateObj.version = version
		  // 设置设备中心的回调函数,下载为多线程
		  function onUpdate(res) {
		    Object.assign(updateObj, res)
		    let pro = document.getElementById('pro')
		    if (!data.isFile) {
		      pro.removeAttribute('max')
		      pro.removeAttribute('value')
		      updateObj.statusPosName = updateObj.statusPos
		      updateObj.statusSizeName = updateObj.statusSize
		    } else {
		      pro.setAttribute('max', updateObj.statusSize)
		      pro.setAttribute('value', updateObj.statusPos)
		      updateObj.statusPosName = getSize(updateObj.statusPos).val
		      updateObj.statusSizeName =getSize(updateObj.statusSize).val
		    }
		  }
		
		  function updateHard() {
		    var pro1 = window.process
		    onUpdate()
		    var hard = Base.getHard()
		    hard.eve({
		      on: function (res) {
		        res = res || {}
		        // 当扫描到数据时
		        if (res.EventName == 'update') {
		          onUpdate(res.Data)
		        }
		      },
		    })
		    var appPath =
		      pro1.mainModule.path ||
		      pro1.mainModule.paths[0].replace('\\node_modules', '')
		    hard.update(
		      {
		        app: pro1.execPath,
		        arg: appPath,
		        version: data.version,
		      },
		      function (res) {
		        // 更新成功，关闭当前程序，等待重新启动
		        onUpdate(res)
		        localStorage.version = last.updateVersionInt
		        localStorage.versionName = last.updateVersion
		        Base.close()
		      },
		      function (res) {
		        // 更新失败，可以继续登录
		        showWrap.vaue= false
		        updateError.value=res.msg || res.Msg || '更新未知错误'
		        setTimeout(function () {
		          func && func()
		        }, 3000)
		      }
		    )
		  }
		  updateHard()
}
//---单位转换
function getSize(size) {
		  var ret = {
		    size: size,
		    val: size + 'B',
		    valNum: size,
		    valUnit: 'B',
		  }
		  var unit = ['KB', 'MB', 'GB', 'TB']
		  for (var i = 0; i < unit.length; i++) {
		    var unitNum = Math.pow(1024, i + 1)
		    if (size > unitNum) {
		      var val = Base.Ronud(size / unitNum, 2)
		      ret = {
		        size: size,
		        val: val + unit[i],
		        valNum: val,
		        valUnit: unit[i],
		      }
		    }
		  }
		  return ret
}
</script>

<style lang="scss" scoped>
	
	.wrap{
	  background: rgba(0, 0, 0, 0.3);
	  position: absolute;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  font-size:0.16rem;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  z-index: 99999;
	}
	.wrap pre {
	  white-space: pre-wrap;
	  word-wrap: break-word;
	  line-height: 0.2rem;
		margin: 0.2rem 0;
	}
	.wrap-content {
	  width: 70%;
	  height: 80%;
	  background: #ffffff;
	}
	.top {
	  border-bottom: 0.1rem solid #ececec;
	  padding: 0.1rem 0.15rem;
	  height: 8%;
	  display: flex;
	  align-items: center;
	}
	.top span{
	  margin-left:0.05rem;
	  color: #ff0000;
		font-size:0.2rem;
		font-weight: bold;
	}
	.middle {
	  padding:0.1rem 0.15rem;
	  border-bottom:0.1rem solid #ececec;
	}
	.middle-content{
		margin-top:0.1rem;
		display: flex;
		align-items: center;
	}
	#pro{
		width:4rem;
		height: 0.4rem;
	}
	.bottom{
	  height: 75%;
	  overflow-y: scroll;
	}
	.bottom-list{
		margin-top: 0.3rem;
		border-bottom: 2px solid #ececec;
		padding: 0 0.24rem;
	}
	.bottom-list div:not(:first-child){
		margin-top: 0.15rem;
	}
	.bottom-list span{
		margin-left: 0.1rem;
	}
	.logining{
		text-align: center;
		margin-top:20%;
		font-size: 0.3rem;
		color: #ff0000;
	}
	.error{
		display: flex;
		height: 0.6rem;
		align-items: center;
		justify-content: space-between;
		font-size: 0.3rem;
		color: #ff0000;
		padding: 0 0.3rem;
		position: absolute;
		bottom: 0;
		width: 100%;
	}
</style>