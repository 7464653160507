<template>
  <div class="camera-data-screen">
    <div class="top">
      <div class="company-box">
        <span class="company">{{ companyName }}</span>
        <span class="date">{{ nowTime }}</span>
      </div>

      <div>
        <button
          class="btn passenger-flow"
          :class="{ active: active === 0 }"
          @click="toggleClick(0)"
        >
          <span class="text">{{ leftTitle }}</span>
        </button>
        <button
          class="btn live-broadcast"
          :class="{ active: active === 1 }"
          @click="toggleClick(1)"
        >
          <span class="text">{{ rightTitle }}</span>
        </button>
      </div>
    </div>
    <div v-if="active === 0">
      <div class="first-group" v-if="JSON.stringify(first) !== '{}'">
        <div class="title-box" @click="reGetVideo">
          <h4 class="group-name">
            <span class="text">{{ first.groupName }}</span>
          </h4>
          <p class="capacity">
            <span>{{ first.keepCount }}</span>
            <span class="small-font">实时</span>
          </p>
        </div>
        <div class="bottom-box">
          <div class="inner-item">
            <span class="classify">进园</span>
            <div class="icon-info">
              <img src="@/assets/image/in.png" />
              <span class="count">{{ first.enterCount }}</span>
            </div>
          </div>
          <div class="inner-item">
            <span class="classify">出园</span>
            <div class="icon-info">
              <img src="@/assets/image/out.png" />
              <span class="count">{{ first.exitCount }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="divider" v-if="otherGroup.length"></div>
      <div class="other-group">
        <div class="item" v-for="item in otherGroup" :key="item.groupId">
          <h4 class="group-name">{{ item.groupName }}</h4>
          <p class="capacity">
            <span>{{ item.keepCount }}</span>
            <span class="small-font">实时</span>
          </p>
          <div class="bottom-box">
            <div class="inner-item">
              <span class="classify">进园</span>
              <div class="icon-info">
                <img src="@/assets/image/in.png" />
                <span class="count">{{ item.enterCount }}</span>
              </div>
            </div>
            <div class="inner-item">
              <span class="classify">出园</span>
              <div class="icon-info">
                <img src="@/assets/image/out.png" />
                <span class="count">{{ item.exitCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="current-box" v-if="personGroup.groupId">
        <h4 class="group-name">{{personGroup.groupName}}</h4>
        <p class="capacity">
          <span>{{personGroup.keepCount}}</span>
          <span class="small-font">实时</span>
        </p>
      </div>
      <span class="not-config" v-if="!isConfig">{{ exceptionMsg }}</span>
    </div>

    <div class="video-box" v-if="active === 1">
      <div class="player" id="player"></div>
      <div class="handle-box">
        <div class="item" v-for="(item, index) in videoArr" :key="item.hardId" @click="handleStartEndClick(item, index)">
          <i class="start" v-if="!item.start"></i>
          <i class="loading" v-if="item.loading"></i>
          <span class="camera-name" v-if="!item.start">{{item.hardName}}</span>
        </div>
      </div>
      <span class="not-config" v-if="!errMsg">{{ errMsg }}</span>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, ref, onMounted, nextTick } from "vue";
import { useRoute } from "vue-router";
import $request from "../assets/js/request.js";
import $common from "../assets/js/common.js";
import debounce from "lodash/debounce";

const config = JSON.parse(sessionStorage.config); // 所有配置信息
const token = config.token; // 登录token
const deviceId = config.deviceId; // 设备ID

const route = useRoute();
onMounted(() => {
  document.title = config?.config?.metaTitle || route.meta.title;
});

const companyName = config?.config?.screenCompanyName || config.companyName; // 景区标题
const leftTitle = config?.config?.leftTitle || "实时客流"; // 左边标题
const rightTitle = config?.config?.rightTitle || "视频直播"; // 右边标题

const active = ref(0); // 标签页索引
let groups = ref([]); // 设备组
let groupTimer = null; // 设备组定时器
let passengerFlowTimer = null; // 客流数据定时器
let isConfig = ref(true); // 屏幕设备是否配置摄像头设备组
let exceptionMsg = ref("未配置设备组，请联系管理人员配置！"); // 异常信息提示
let nowTime = new Date().Format("yyyy/MM/dd"); // 当前时间
let first = ref({}); // 第一组摄像头
let otherGroup = ref([]); // 其他组摄像头
let personGroup = ref({}); // 人头摄像头

// 获取人头类型的摄像头数据
function getPersonGroup() {
  const today = new Date().Format("yyyy-MM-dd")
  const data = {
    deviceId,
    dateType: 2,//时间单位:0-年,1-月,2-日,3-小时,4-分钟
    resultType: 2,//时间单位:0-年,1-月,2-日,3-小时,4-分钟
    eventTimeFrom: today + ' 00:00:00',
    eventTimeTo: today + ' 23:59:59',
  }
  $request("tbd-report/hk/person/group?token=" + token, data)
    .then((res) => {
      if (res.code == "00") {
        if(res.data.length) {
          personGroup.value = res.data[0]
        }
      } else {
        console.log(res.data.message)
      }
    })
    .catch((err) => {
      console.log(err)
    });
}


// 获取设备组
function getGroups() {
  clearTimeout(passengerFlowTimer);
  $request("tbd-report/hk/display/list?token=" + token, { deviceId })
    .then((res) => {
      if (res.code == "00") {
        if (!res.data.list.length) return;
        isConfig.value = true;
        groups.value = res.data.list;
        getPassengerFlow();
      } else {
        isConfig.value = false;
        exceptionMsg.value = res.data.message;
      }
    })
    .catch((err) => {
      isConfig.value = false;
      exceptionMsg.value = err;
    });

  groupTimer = setTimeout(getGroups, 1000 * 60 * 5);
}

// 获取客流详情
function getPassengerFlow() {
  const item = groups.value[0];
  const data = {
    deviceId, //设备id
    dateType: item.dateType, //时间单位
    resultType: item.resultType, //结果类型
    eventTimeFrom: $common.today() + " 00:00:00", //开始时间
    eventTimeTo: $common.today() + " 23:59:59", //结束时间
  };
  $request("tbd-report/hk/flow/group?token=" + token, data)
    .then((res) => {
      if (res.code == "00") {
        item.child = ref(res.data || []);
        if (!item.child.length) {
          isConfig.value = false;
          exceptionMsg.value = "暂无摄像头信息";
          return;
        }
        first.value = item.child[0];
        otherGroup.value = item.child.slice(1);
        getPersonGroup()
      } else {
        isConfig.value = false;
        exceptionMsg.value = res.data.message;
      }
    })
    .catch((err) => {
      isConfig.value = false;
      exceptionMsg.value = err;
    });

  passengerFlowTimer = setTimeout(getPassengerFlow, 1000);
}

getGroups();

let videoArr = ref([]); // 摄像头直播流数据
let errMsg = ref(""); // 请求摄像头错误信息
let errIndexList = []; // 播放出错的摄像头索引
// 获取视频监控
function getVideo() {
  $request("tbd-report/hk/video/list?token=" + token, { deviceId })
    .then(async (res) => {
      if (res.code == "00") {
        if (errIndexList.length) {
          await nextTick();
          console.log(
            `%c重新请求`,
            "font-size:36px;color:#fff;background-color:#000;"
          );
          console.log(errIndexList);
          let hasPlay = {};
          while (errIndexList.length > 0) {
            let index = errIndexList.shift();
            if(hasPlay[index]){
              return;
            }
            hasPlay[index] = true;
            const ele = res.data[index];
            console.log(ele);
            handlePlyer(ele, index, "replay");
          }
        } else {
          videoArr.value = res.data;
          if (!videoArr.value.length) {
            errMsg.value = "无摄像头信息";
            return;
          }
          await nextTick();

          startPlayer();
        }
      } else {
        errMsg.value = res.message;
      }
    })
    .catch((err) => {
      errMsg.value = err;
    });
}

const reGetVideo = debounce(getVideo, 10000); // 视频播放错误时的防抖函数

// 准备开始播放player
let player = null; // h5player控件
function startPlayer() {
  // 1.初始化player
  initPlayer();

  // 2.分隔窗口（有几个摄像头，就有几个窗口）
  let splitNum = videoArr.value.length;
  player.JS_ArrangeWindow(splitNum).then(
    () => {
      console.log(`arrangeWindow to 1x${splitNum} success`);
    },
    (e) => {
      console.log(e);
    }
  );

  // 3.处理播放器开始、加载状态，开启播放
  // videoArr.value.forEach((item, index) => handlePlyer(item, index));
}

// 初始化player
function initPlayer() {
  if (player != null) return;
  player = new window.JSPlugin({
    szId: `player`,
    szBasePath: "./demo/",
    iMaxSplit: videoArr.value.length,
    iCurrentSplit: 1,
    openDebug: false,
    oStyle: {
      borderSelect: "#000",
    },
  });
  // 事件回调绑定
  player.JS_SetWindowControlCallback({
    windowEventSelect: function (iWndIndex) {
      //插件选中窗口回调
      console.log("windowSelect callback: ", iWndIndex);
      // if(!videoArr.value[iWndIndex].start) {
      //   realPlay(videoArr.value[iWndIndex], iWndIndex)
      // }else {
      //   stopPlay(iWndIndex)
      // }
    },
    pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {
      //插件错误回调
      console.log(
        `%c第${iWndIndex + 1}个摄像头播放错误`,
        "font-size:18px;color:#fff;background-color:red;"
      );
      errIndexList.push(iWndIndex);
      reGetVideo();
    },
    windowEventOver: function (iWndIndex) {
      //鼠标移过回调
      // console.log(iWndIndex)
    },
    windowEventOut: function (iWndIndex) {
      //鼠标移出回调
      // console.log(iWndIndex)
    },
    windowEventUp: function (iWndIndex) {
      //鼠标mouseup事件回调
      // console.log(iWndIndex)
    },
    windowFullCcreenChange: function (bFull) {
      //全屏切换回调
      console.log("fullScreen callback: ", bFull);
    },
    firstFrameDisplay: function (iWndIndex, iWidth, iHeight) {
      //首帧显示回调
      console.log("firstFrame loaded callback: ", iWndIndex, iWidth, iHeight);
      videoArr.value[iWndIndex].start = true;
    },
    performanceLack: function (iWndIndex) {
      //性能不足回调
      if (typeof iWndIndex == "number") {
        console.log(
          `%c第${iWndIndex + 1}个性能不足`,
          "font-size:18px;color:#fff;background-color:red;"
        );
        errIndexList.push(iWndIndex);
        reGetVideo();
      } else {
        console.log("performanceLack callback: ");
      }
    },
    captureSuccess: function(value, index) {
      videoArr.value[index].imgUrl = value
      stopPlay(index)
    }
  });
}

// 处理播放器开始、加载状态，开启播放
function handlePlyer(item, index, tag) {
  try {
    item.start = false;
    item.loading = false;
    realPlay(item, index, tag);
  } catch (e) {
    console.log(e);
  }
}

// 开始播放
function realPlay(item, index, tag) {
  const playURL = item.videoUrl;
  const mode = 0; // 播放模式：0-普通模式;1-高级模式
  if (tag === "replay") {
    console.log(
      `%c第${index + 1}个摄像头重放`,
      "font-size:18px;color:#fff;background-color:#000;"
    );
  }
  player.JS_Play(playURL, { playURL, mode }, index).then(
    () => {
      console.log(
        `%cplay success ${index + 1}`,
        "font-size:18px;color:#fff;background-color:green;"
      );
      item.loading = false;
      // if(!item.imgUrl) {
      //   capture(index)
      // }
    },
    (e) => {
      errIndexList.push(index);
      reGetVideo();
      console.log(e);
    }
  );
}

// 停止全部窗口
function stopAllPlay(fn) {
  player.JS_StopRealPlayAll().then(
    () => {
      console.log("stopAllPlay success");
      videoArr.value.forEach(item => item.start = false)
      fn && fn();
    },
    (e) => {
      console.error(e);
    }
  );
}

// 截图
function capture(index) {
  player.JS_CapturePicture(index, 'img', 'JPEG').then(
    (res) => { console.log('capture success', res) },
    e => { console.error(e) }
  )
}

// 暂停
function playbackPause(index) {
  player.JS_Pause(index).then(
    () => { console.log('playbackPause success') },
    e => { console.error(e) }
  )
}

// 停止播放
function stopPlay(index) {
  player.JS_Stop(index).then(
    () => {
      videoArr.value[index].start = false
      console.log(
        `%c第${index + 1}个停止成功`,
        "font-size:18px;color:#fff;background-color:green;"
      );
    },
    (e) => {
      console.log(e);
    }
  );
}

// 视频的开始与暂停
function handleStartEndClick(item, index) {
  if(item.start) {
    stopPlay(index)
  }else {
    stopAllPlay(realPlay(item, index))
  }
}

// 切换内容页
function toggleClick(tabIndex) {
  if (active.value === tabIndex) return;

  if (tabIndex === 1) {
    // 显示视频直播
    // 关闭循环
    clearTimeout(groupTimer);
    clearTimeout(passengerFlowTimer);

    // 获取摄像头数据
    getVideo();
    active.value = tabIndex;
  } else {
    // 显示实时人流时
    const fn = () => {
      //停止播放成功后的回调函数
      getGroups();
      player = null;
      videoArr.value = [];
      errIndexList = []; // 清空视频播放错误的摄像头索引
      active.value = 0;
    };

    // 停止所有播放播放
    stopAllPlay(fn);
  }
}

onBeforeUnmount(() => {
  // 关闭循环
  clearTimeout(groupTimer);
  clearTimeout(passengerFlowTimer);

  // 停止播放，并释放播放器控件
  stopAllPlay();
  player = null;
});
</script>

<style lang="scss" scoped>
.camera-data-screen {
  position: relative;
  max-width: 480px;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 16px 16px 16px;
  color: #fff;
  background: url("@/assets/image/phoneBg.png") 0 0/100% repeat-y;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 36px 0;

  .company-box {
    text-align: center;
    color: #fff;
    font-size: 14px;
    margin-bottom: 44px;
    .company {
      display: block;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .date {
      display: block;
      position: relative;
      &::after {
        content: '';
        width: 90%;
        height: 4px;
        position: absolute;
        left: 2px;
        bottom: -6px;
        background: url('@/assets/image/dateBg.png') no-repeat;
      }
    }
  }

  .btn {
    position: relative;
    width: 104px;
    height: 41px;
    font-size: 18px;
    color: #4659ce;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url("@/assets/image/btn.png") no-repeat;
      background-size: 100% 100%;
    }

    &.active {
      color: #fff;

      &::before {
        background: url("@/assets/image/activeBtn.png") no-repeat;
        transform: rotateY(0);
      }

      &.live-broadcast::before {
        transform: rotateY(180deg);
      }
    }
  }

  .passenger-flow {
    margin-right: 4px;

    &::before {
      transform: rotateY(180deg);
    }
  }
}

.text {
  position: relative;
}

.group-name {
  font-size: 16px;
}



.first-group {
  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .group-name {
    position: relative;
    margin-bottom: 16px;

    &::before {
      content: "";
      position: absolute;
      width: 32px;
      height: 8px;
      left: -1px;
      bottom: -2px;
      background: url("@/assets/image/decoration.png") no-repeat;
    }
  }
}

.small-font {
  font-size: 12px;
  color: #ccc;
  margin-left: 12px;
}

.capacity {
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 16px;
}

.divider {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #757b91;
  margin: 24px 0;
}

.bottom-box {
  display: flex;
  justify-content: space-between;

  .inner-item {
    width: 48%;
    height: 102px;
    border-radius: 16px;
    background-color: #ffffff30;
    backdrop-filter: blur(30px);
    padding: 12px;
    box-sizing: border-box;

    .classify {
      font-size: 16px;
    }
  }

  .icon-info {
    margin-top: 16px;

    img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }

    .count {
      position: relative;
      top: 6px;
      font-size: 24px;
    }
  }
}

.other-group {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 48%;
    height: 177px;
    background: #4659ce;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px 8px 8px 8px;
    padding: 12px 8px 16px;
    margin-bottom: 4%;

    .capacity {
      font-size: 40px;
      margin-bottom: 0;
    }
  }

  .item:nth-child(2n-1) {
    margin-right: 4%;
  }

  .inner-item {
    width: 48%;
    height: auto;
    padding: 4px;
    border-radius: 8px;

    .classify {
      font-size: 10px;
    }

    .icon-info {
      margin-top: 8px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .count {
      font-size: 14px;
    }
  }

  .inner-item:nth-child(2n-1) {
    margin-right: 4px;
  }
}

.current-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #4659ce;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 0 16px 0 12px;

  .capacity {
    font-size: 36px;
    margin-bottom: 0;
  }
}

.not-config {
  font-size: 24px;
  color: #fff;
  letter-spacing: 4px;
}

.video-box {
  position: relative;
  & >>> .sub-wnd {
    margin-bottom: 16px !important;
  }
  .handle-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .item {
    position: relative;
    width: 100%;
    flex: 1;
    margin-bottom: 16px;
    .top-box {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 6px;
      color: #ccc;
      font-size: 16px;
      z-index: 99;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & > div {
      width: 100%;
      height: 250px;
    }

    .start,
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: rgba($color: #fff, $alpha: 0.5);
      color: #fff;
    }

    .start {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-20%, -50%);
        content: "";
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-left-color: #fff;
      }
    }

    .loading {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-125%, -50%);
        content: "";
        width: 4px;
        height: 12px;
        background: #fff;
        border-radius: 2px;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(50%, -50%);
        content: "";
        width: 4px;
        height: 12px;
        background: #fff;
        border-radius: 2px;
      }
    }

    .camera-name {
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      font-size: 14px;
      color: #fff;
      margin-top: 6px;
    }
  }
}
</style>
