export default {
  today:function(num){//默认当天
    var now = new Date()
    var day = ''
    if(num){
      day = num
    }else{
      day = ('0' + now.getDate()).slice(-2)
    }
    var month = ('0' + (now.getMonth() + 1)).slice(-2)
    var today = now.getFullYear()+'-'+(month)+'-'+(day)
    return today
  }
}
