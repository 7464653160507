import axios from 'axios'
let baseUrl= ''
if(window.location.protocol=='https:'){
  baseUrl= 'https://service.tourbida.com/'
}else{
  baseUrl= 'http://service.tourbida.com/'
}
const request = (url = '', data = {}) => {
  return new Promise((resolve, reject) => {
    axios.post(baseUrl+url, data).then((res) => {
      resolve(res.data)
    }).catch((err) => {
      reject(err)
    })
  })
}
export default request