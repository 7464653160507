<template>
  <div class="capacity-screen">
    <head>
      <h3>{{companyName}}欢迎您</h3>
      <p>{{nowTime}}</p>
    </head>
    <div class="content">
      <div v-for="item in groups" :key="item.groupId" v-show="item.child && item.child.length">
        <div class="item" v-for="(item2, index2) in item.child" :key="index2" v-html="item2.formatText"></div>
      </div>

    </div>
    <span class="not-config" v-if="!isConfig">{{exceptionMsg}}</span>
  </div>
</template>

<script setup>
  import { ref, onBeforeUnmount} from 'vue'
  import $request from '../assets/js/request.js'
  import $common from '../assets/js/common.js'


  const token = JSON.parse(sessionStorage.config).token
  const deviceId = JSON.parse(sessionStorage.config).deviceId
  const companyName = JSON.parse(sessionStorage.config).companyName
  const capacityFormat = JSON.parse(sessionStorage.config).config.capacityFormat // 显示格式
  const maxPercent = JSON.parse(sessionStorage.config).config.maxPercent || 100 // 显示数值最大百分比（默认100%）
  let nowTime = ref('')// 当前时间
  let isConfig = ref(true) // 屏幕设备是否配置摄像头设备组
  let exceptionMsg = ref('未配置设备组，请联系管理人员配置！') // 异常信息提示
  let groups = ref([]) // 设备组
  let groupTimer = null // 设备组定时器
  let passengerFlowTimer = null // 客流数据定时器

  if(!capacityFormat) {
    isConfig = false
    exceptionMsg.value = '请配置显示格式！'
  }


  // 获取设备组
  function getGroups() {
    clearTimeout(passengerFlowTimer)

    $request('tbd-report/hk/display/list?token='+token, { deviceId }).then((res) => {
		  if (res.code == '00') {
        if(!res.data.list.length) return
        isConfig.value = true
        groups.value = res.data.list
        getPassengerFlow()
      }else{
        isConfig.value = false
        exceptionMsg.value = res.data.message
      } 	
    }).catch((err)=>{
      isConfig.value = false
      exceptionMsg.value = err
    })

    groupTimer = setTimeout(getGroups, 1000 * 60 * 5)
  }

  // 获取客流详情
  function getPassengerFlow() {
    const item = groups.value[0]
    const data = {
      deviceId,//设备id
      dateType: item.dateType,//时间单位
      resultType: item.resultType,//结果类型
      eventTimeFrom: $common.today() + ' 00:00:00',//开始时间
      eventTimeTo: $common.today() + ' 23:59:59',//结束时间
    }
    $request('tbd-report/hk/flow/group?token='+token, data).then((res) => {
		  if (res.code == '00') {
        item.child = ref(res.data || [])
        if(!item.child.length) {
          isConfig.value = false
          exceptionMsg.value = '暂无摄像头信息'
          return
        }
        handleDisplayFormat(item.child)
      }else{
        isConfig.value = false
        exceptionMsg.value = res.data.message
      }
    }).catch((err)=>{
      isConfig.value = false
      exceptionMsg.value = err
    })

    passengerFlowTimer = setTimeout(getPassengerFlow, 1000)
  }

  // 处理显示格式
  function handleDisplayFormat(arr) {
    if(!arr.length) return

    arr.forEach(item => {
      let originFormat = capacityFormat.replaceAll('{', ' ').replaceAll('}', ' ')

      for (const key in item) {
        if(capacityFormat.includes(key)) {
          if(typeof item[key] === 'number') {
            item[key] = Math.round(item[key] * maxPercent * 0.01)
          }
          originFormat = originFormat.replace(key, item[key])
        }
      }
      item.formatText = originFormat
    })
  }

  getGroups()

  //---获取时间
  function nextTime() {
    nowTime.value = new Date().Format('yyyy - MM - dd hh:mm:ss')
    setTimeout(nextTime, 1000)
  }
  nextTime()

  onBeforeUnmount(() => {
    clearTimeout(groupTimer)
    clearTimeout(passengerFlowTimer)
  })

</script>

<style lang="scss" scoped>
  .capacity-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
    background-color: #2c445a;
    color: #fff;
    font-size: 0.48rem;
    head {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      height: 35%;
      border-bottom: 1px solid #fff;
      h3 {
        letter-spacing: 20px;
      }
      p {
        font-size: 0.32rem;
      }
    }
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.36rem;
    }
    .item {
      margin-bottom: 0.24rem;
    }
  }
  .not-config {
    color: red;
  }
</style>