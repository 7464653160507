<template>
  <router-view/>
	<div class="tip-wrapper" v-if="isBreak">
	  <div class="tip-box">
	    网络已断开，请检查您的网络
	  </div>
	</div>
</template>

<script setup>
import axios from 'axios'
import {ref} from 'vue'
let isBreak=ref(false)

let heartInterface=()=>{
  let token=''
  if(sessionStorage.config){
	  token=JSON.parse(sessionStorage.config).token
  }
  let baseUrl= ''
  if(window.location.protocol=='https:'){
	  baseUrl= 'https://service.tourbida.com/'
  }else{
	  baseUrl= 'http://service.tourbida.com/'
  }
  axios.get(baseUrl+'tbd-system/login/heart?token='+token).then(res => {
    if (res.data.code == '00') {
      isBreak.value = false
      setTimeout(heartInterface, res.data.data * 1000)
    } else {
      isBreak.value = true
      setTimeout(heartInterface,1000)
    }
  }).catch(err => {
    isBreak.value = true
    setTimeout(heartInterface,  1000)
  })
}
heartInterface()
</script>

<style lang="scss">
	#app{
		height: 100%;
	}
	.tip-wrapper {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  position: fixed;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background:rgba(0, 0, 0, 0.5);
	  z-index: 99999;
	  .tip-box {
	    width:30%;
	    padding: 0.4rem;
	    background: #fff;
	    border-radius: 4px;
	    color:#ff0000;
	    font-size: 0.32rem;
	  }
	}
</style>
