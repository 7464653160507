import { createRouter, createWebHashHistory} from 'vue-router'
import sortScreen from '../views/sortScreen.vue'//排号屏页面
import capacityScreen from '../views/capacityScreen.vue'//容量屏页面
import cameraDataScreen from '../views/cameraDataScreen.vue'//摄像头数据页面
import scenicStatistics from '../views/scenicStatistics.vue'//景区统计页面
import sortScreenPhone from '../views/sortScreenPhone.vue'//排号屏页面手机版本
import login from '../views/login.vue'

const routes = [
  {
	  path: '/',
	  name: 'login',
	  component: login
  },
  {
    path: '/sortScreen',
    name: 'sortScreen',
    component: sortScreen,
    meta: {
		  requireAuth: true
    }
  },
  {
    path: '/capacityScreen',
    name: 'capacityScreen',
    component: capacityScreen,
    meta: {
		  requireAuth: true
    }
  },
  {
    path: '/cameraDataScreen',
    name: 'cameraDataScreen',
    component: cameraDataScreen,
    meta: {
      title: '实时数据',
		  requireAuth: true
    }
  },
  {
    path: '/scenicStatistics',
    name: 'scenicStatistics',
    component: scenicStatistics,
    meta: {
      title: '景区统计',
		  requireAuth: true
    }
  },
  {
    path: '/sortScreenPhone',
    name: 'sortScreenPhone',
    component: sortScreenPhone,
    meta: {
      title: '排号数据',
		  requireAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
