<template>
	<!-- 景区统计页面 -->
	<div class="wrap">
		<div class="title">
			{{config.companyName}}欢迎你
		</div>
		
		<div class="content">
			<div>
				{{time}}
			</div>
			
			<div class="number">
				<p>在园总量</p>
				<p>
					{{numberObj.insidePersonNum}}
					<span>人</span>
				</p>
			</div>
			
			<div class="number">
				<p>入园总量</p>
				<p>
					{{numberObj.enterPersonNum}}
					<span>人</span>
				</p>
			</div>
			
			<div class="number">
				<p>出园总量</p>
				<p>
					{{numberObj.outPersonNum}}
					<span>人</span>
				</p>
			</div>
			<div v-if="errMsg" style="margin-top: 2vh;color: #ff0000;">
				错误提示：{{errMsg}}
			</div>
		</div>
	</div>
</template>

<script setup>
import {ref,onBeforeUnmount} from 'vue'
import axios from 'axios'
import $request from '../assets/js/request.js'
import $commonJs from '../assets/js/common.js'
let config=''
let token=''
if(sessionStorage.config){
  //登录缓存的本地信息
  config=JSON.parse(sessionStorage.config)
  token=config.token
}
let numberObj=ref('')
let errMsg=ref('')
//---初始化获取数据
let timer=''
let data = {
  companyId: config.companyId,
  queryStartDate:$commonJs.today(),
  queryEndDate:$commonJs.today(),
  areaType: 0,//区域类型，目前默认为0
}
function initFun(){
  $request('tbd-report/realtimeTravellerFlow/loadCount?token='+token, data).then(res => {
    if(res.code == '00') {
      numberObj.value = res.data
    } else {
      errMsg.value=res.message
    }
  }).catch(()=>{
    errMsg.value='请求失败'
  })
  timer=setTimeout(initFun,1000*10)
  onBeforeUnmount(()=>{
	  clearTimeout(timer)
  })
}
initFun()
//---获取时间
let time=ref('')
function nextTime() {
  time.value=new Date().Format('yyyy-MM-dd hh:mm:ss')
  setTimeout(nextTime, 1000)
}
nextTime()
</script>

<style lang="scss" scoped>
	.wrap{
		background:url('../assets/image/picture.jpg') no-repeat;
		font-size: 16px;
		height:100%;
		background-size:100% 100%;
	}
	.title{
		font-size: 20px;
		font-weight: bold;
		padding-top: 10vh;
		text-align: center;
		color: #fff;
	}
	.content{
		width: 70%;
		border-radius: 10px;
		background: #fff;
		padding: 30px 0;
		margin: 5vh auto 0 auto;
		text-align: center;
	}
	.number{
		margin-top: 6vh;
		color: #0f5efe;
		p:last-child{
			font-size: 32px;
			font-weight: bold;
			span{
				font-size: 12px;
			}
		}
	}
</style>