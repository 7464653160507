import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/common.css'
import './assets/js/responsive.js'

Date.prototype.Format = function(fmt) {
  // 时间格式化
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
  return fmt
} //调用new Date().Format("yyyy-MM-dd hh:mm");

createApp(App).use(store).use(router).mount('#app')
//路由守卫

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    let token=''
    if(sessionStorage.config){
      token=JSON.parse(sessionStorage.config).token
    }
    if (!token) {
      function getUrlKey(name){//---------------------------------------------------------------获取url
			  return(new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(decodeURIComponent(location.href)) || [, ''])[1].replace(/\+/g, '%20') || ''
      }
      let hardKey=getUrlKey('hardKey')
      router.push('/?hardKey='+hardKey)
    }else{
      next()
    }
  }else{
    next()
  }
})

//全屏
NW && NW.windowFull && NW.windowFull()